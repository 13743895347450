<template>
  <v-app>

    <v-container pt-2 pb-2 mt-2 mb-10>
      <v-layout justify-center>
        <v-avatar tile size="40%">
          <img src="@/assets/logo.png"> 
        </v-avatar>
      </v-layout>
    </v-container>

    <!-- <Toaster /> -->

    <v-card width="600px" class="mx-auto mt-5" color="yellow lighten-5" elevation="18" shaped>

      <v-card-text>

        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">ログイン</h1>
          <p class="login_state" v-show="login_state == false">
            ログインID、またはパスワードが間違っています。
          </p>
        </div>

        <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
          <v-text-field 
            prepend-icon="mdi-account-circle"
            label="ログインID"
            v-model="provider_login_id"
            :counter="20"
            :rules="provider_login_id_rules"
            required
            clearable
          />

          <v-text-field
            v-bind:type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock" 
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            @click:append="showPassword = !showPassword" 
            v-model="provider_login_pwd" 
            :counter="20"
            :rules="provider_login_pwd_rules"
            required
            clearable
          />

          <v-card-actions>
            <v-layout justify-space-around>
              <router-link v-if="!show_deactivate_link" to="/signup">新規登録の方はこちら</router-link>
              <router-link v-if="show_deactivate_link" to="/reactivate">パスワードを忘れた方はこちら</router-link>
            </v-layout>

            <v-layout justify-end>
              <v-btn 
                class="info"
                @click="login"
                :loading="loading"
                >
                ログイン
              </v-btn>
            </v-layout>

          </v-card-actions>
        </v-form>

      </v-card-text>
    </v-card>

  </v-app>
</template>

<script>
// import Toaster from '../components/modules/Toaster.vue'

export default {
  // ログイン画面の初期値
  data () {
    const max = 20;
    return {
      valid: false,
      showPassword : false,
      show_deactivate_link: false,
      provider_login_id: this.$route.query.id != undefined ? this.$route.query.id : '',
      provider_login_pwd: '',
      login_state: true,
      loading: false,
      max,
      provider_login_id_rules: [
        v => !!v || "ログインIDは必須項目です。",
        v => (v && v.length <= max) || `ログインIDは${max}文字以内で入力してください。`,
      ],
      provider_login_pwd_rules: [
        v => !!v || "パスワードは必須項目です。",
        v => (v && v.length <= max) || `パスワードは${max}文字以内で入力してください。`
      ],
    };
  },

  mounted () {
    // console.log(this.$testServerApi());
  },

  components: {
    // Toaster,
  },

  computed: {

  },

  // ログイン処理
  methods: {
    validation () {
      return this.$refs.form.validate();
    },
    login () {
      if (!this.validation()) return;
      this.loading = true
      this.$axios
        .post('providers/signin',
          {
            provider_login_id: this.provider_login_id,
            provider_login_pwd: this.provider_login_pwd
          }
        )
        .then((login_response) => 
          {
            // console.log("login_success");
            // console.log(login_response);
            
            this.$store.commit(
              'updateId', {
                provider_id: login_response.data.provider_id,
                sess_id: login_response.data.sess_id
              }
            )
            // console.log('storeの中のprovider_id',this.$store.state.provider_id);
            // console.log('storeの中のsess_id',this.$store.state.sess_id);
            this.$router.push('/qrdisplay')
          }
        )
        .catch((login_error) => 
          {
            console.log(login_error.response);
            this.login_state = false
            this.show_deactivate_link = true
          }
        )
        .finally(()=>
        {
          this.loading = false;
        });
      // this.provider_login_id = "";
      // this.provider_login_pwd = "";
    },
  }
};
</script>

<style scoped>

.login_state {
  color: red;
  font-size: 15px;
}
</style>