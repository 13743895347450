<template>

  <div id="settings">
    <v-container text-center wrap md12>
      <v-card class="mx-auto" outlined max-width="1200">
        <v-card-title>
          <v-icon>mdi-cog</v-icon>
          設定
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item>
              <v-switch
                v-model="machi_switch"
                :loading="machi_load"
                flat
                label="待ち人数を顧客に表示する"
                @change="set_machi_enable(machi_switch)">
              </v-switch>
            </v-list-item>
          </v-list>
        </v-card-text>

      </v-card>
    </v-container>

  </div>
</template>

<script>
export default {  
  name: 'Settings',
  data: () => ({
    machi_switch: false,
    machi_load: "warning"
  }),

  mounted: function () {
    this.get_machi_enable();
  },

  methods: {
    get_machi_enable() {
      this.$axios.get('provider/settings',
      {
        params:
        {
          provider_id: this.$store.state.provider_id,
          sess_id: this.$store.state.sess_id
        }
      }).then((response) => {
        this.machi_switch = response.data.machi_flag;
        this.machi_load = null;
      })
    },
    set_machi_enable(flg) {
      this.$axios.post('provider/settings',
      {
        provider_id: this.$store.state.provider_id,
        sess_id: this.$store.state.sess_id,
        machi_flag: flg
      }).then((response) => {
        console.log(response.data);
      })
    }
  }
}
</script>
