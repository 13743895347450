<template>
  <v-app>
      <template v-if="this.$store.getters.isAuthenticated">
        <Header />
      </template>
      <v-main>
        <router-view />
      </v-main>
      <template v-if="this.$store.getters.isAuthenticated">
        <Footer />
      </template>
  </v-app>
</template>

<script>
import Header from './components/modules/Header.vue'
import Footer from './components/modules/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  }
}
</script>