<template>

  <div class="CallList">
    <v-container text-center wrap md12>
      <v-card class="mx-auto" outlined max-width="1200">
        <v-card-title>
          <v-icon>mdi-bell</v-icon>
          呼出リスト
        </v-card-title>
        <v-data-table 
          :headers="headers" 
          :items="items" 
          :items-per-page="30" 
          :footer-props="{
            itemsPerPageOptions: [30]
          }"
          class="elevation-1"
        >
        
          <template v-slot:body="{items}">
            <!-- <transition-group name="slide-fade"> -->
            <tbody>
              <tr v-for="(item, index) in items" :key="item.id">
                <td v-if="item.bill_no == null">
                  受付番号 {{ item.accept_no }}
                </td>

                <td v-else>
                  札番号 {{ item.bill_no }}
                </td>

                <td>{{ item.accept_dt }}</td>

                <td>
                  <v-btn
                    class="info" large
                    :disabled="item.bill_no!=null"
                    @click="changeState(index, 2)">
                    <span v-if="item.accept_st==2">呼出中<v-icon class="calling-animate">mdi-bell-ring-outline</v-icon></span>
                    <span v-else>呼出</span>
                  </v-btn>
                </td>

                <td>
                  <v-btn
                    class="info" large
                    @click="changeState(index, 10)">
                    完了
                  </v-btn>
                </td>

              </tr>
            </tbody>
            <!-- </transition-group> -->
          </template>
          
        </v-data-table>
      </v-card>
    </v-container>
  </div>
  
</template>

<script>
export default {
  name: 'CallList',
  data: () => ({
    headers: [
      {
        text: "受付番号",
        sortable: false,
        align: "center"
      },
      {
        text: "受付日時",
        sortable: false,
        align: "center"
      },
      {
        text: "呼び出し",
        sortable: false,
        align: "center"
      },
      {
        text: "完了",
        sortable: false,
        align: "center"
      }
    ],
    items: [],
    lastAction: null
  }),

  // ページ読み込み時に受付カードを読み取る
  mounted: function () {
    this.getAccepts();
    // axiosと書き換え定期実行
    // this.timerObj = setInterval(this.getAccepts.bind(this), 1000);
    this.setTimer(1000);
    // console.log(AcceptStatus.END);
  },

  // 画面切り替え時にsetInterval停止
  beforeDestroy () {
    // console.log('clearInterval')
    this.clearTimer();
  },

  methods: {
    setTimer: function(msec) {
      clearInterval(this.timerObj);
      this.timerObj = setInterval(this.getAccepts.bind(this), msec);
    },
    clearTimer: function() { clearInterval(this.timerObj); },
    actionTimeStamp: function() { this.lastAction = Date.now(); },
    // ステータスの変更
    changeState: function(index, accept_status) {
      this.actionTimeStamp();
      // item.state = !item.state
      this.items[index].accept_st = accept_status;
      
      // 呼出かつ番号受付のみpush通知を送る
      if (accept_status == 2 && this.items[index].bill_no == null) {
        this.pushUser(this.items[index]);
      }
      // 呼出or完了時のみステータスを変更
      if (accept_status == 2 || accept_status == 10) {
        this.updateUserStatus(this.items[index]);
      }

      if (accept_status == 10) {
        this.pushComplete(this.items[index]);
        this.items.splice(index, 1);
      }
    },

    // ユーザーを呼び出す
    pushUser: function(item) {
      // api送信用の変数設定
      const provider_id = this.$store.state.provider_id;
      const sess_id = this.$store.state.sess_id;
      const btn_accept_key = item.accept_key;
      const call_type = "CALL";
      
      // console.log(this.items[index])

      // 呼出用のapi発行url
      const push_url = 'push/' + btn_accept_key;

      this.$axios
      .post(push_url,
        { 
          provider_id,
          sess_id,
          call_type
        }
      )
      .then(push_user_response => {
        console.log(push_user_response)
        // console.log('success')
        }
      )
      .catch((push_user_error) => {
        console.log(push_user_error);
        // console.log("error");
        // console.log('アクセスキー' + this.items[index].accept_key)
        // console.log('受付番号 ' + this.items[index].accept_no);
      });
    },

    // 完了通知
    pushComplete: function(item) {
      // api送信用の変数設定
      const provider_id = this.$store.state.provider_id;
      const sess_id = this.$store.state.sess_id;
      const btn_accept_key = item.accept_key;
      const call_type = "COMPLETE";
      
      // console.log(this.items[index])

      // 呼出用のapi発行url
      const push_url = 'push/' + btn_accept_key;

      this.$axios
      .post(push_url,
        { 
          provider_id,
          sess_id,
          call_type
        }
      )
      .then(push_user_response => {
        console.log(push_user_response)
        // console.log('success')
        }
      )
      .catch((push_user_error) => {
        console.log(push_user_error);
        // console.log("error");
        // console.log('アクセスキー' + this.items[index].accept_key)
        // console.log('受付番号 ' + this.items[index].accept_no);
      });
    },

    // 完了押下後、ユーザーステータスを完了に更新する
    updateUserStatus: function(item) {
      // console.log('アクセスキー' + this.items[index].accept_key)

      // api送信用の変数設定
      const provider_id = this.$store.state.provider_id;
      const sess_id = this.$store.state.sess_id;
      const complete_accept_key = item.accept_key;
      const accept_st = item.accept_st;

      // 呼出用のapi発行url
      const complete_url = 'acceptcards/' + complete_accept_key;

      this.$axios
      .put(complete_url,
        { 
          provider_id,
          sess_id,
          accept_st: accept_st,
          call_plan_dt: "2020-07-07 12:34:56",
          place_seq: 1
        }
      ).then(() => {
        // console.log(update_user_response)
      })
      .catch(() => {
        // console.log(update_user_error);
      });
    },

    getAccepts: function() {
      this.$axios
        .get('acceptcards', 
          { params: 
            {
              provider_id: this.$store.state.provider_id,
              sess_id: this.$store.state.sess_id,
              accept_mode: "w"
            }
          }
        )
        .then((acceptcards_response) => {
          // ユーザーの操作から指定秒内は更新をしないようにする
          if (this.lastAction != null && Date.now() - this.lastAction < 3000) { return; }

          this.acceptcards = acceptcards_response;

          // dataプロパティにresponseデータを渡す
          this.items = acceptcards_response.data;

          // console.log(acceptcards_response)
          // console.log(this.items)
          }
        )
    }
  }
}
</script>

<style scoped>
/* .v-card__title {
  font-size: 30px;
} */

.calling-animate {
  animation: calling .1s infinite;
}
@keyframes calling {
  0% {transform: translate(0px, 0px); }
  50% {transform: translate(2px, 0px); }
  10% {transform: translate(0px, 0px); }
}

.text-start {
  font-size: 15px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>