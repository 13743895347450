<template>
  <v-app>
    <v-container pt-2 pb-2 mt-2 mb-10>
      <v-layout justify-center>
        <v-avatar tile size="40%">
          <img src="@/assets/logo.png"> 
        </v-avatar>
      </v-layout>
    </v-container>

    <v-card width="600px" class="mx-auto mt-5" color="yellow lighten-5" elevation="18" shaped>
      <v-card-text v-if="!show_signuped_msg">
        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">サインアップ</h1>
          <!-- <p>{{ generateId() }}</p> -->
          <p class="err_msg" v-show="show_err_msg" v-for="msg in err_messages" v-bind:key="msg">{{ msg }}</p>
        </div>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-text-field 
            label="会社名*"
            v-model="provider_name"
            :rules="provider_name_rules"
            prepend-icon="mdi-office-building" 
            maxlength=20
            required
          />

          <v-text-field
            label="メールアドレス*"
            v-model="provider_email"
            :rules="provider_email_rules"
            prepend-icon="mdi-email"
            maxlength=255
            required
          />

          <v-text-field
            label="電話番号*"
            v-model="provider_tel"
            :rules="provider_tel_rules"
            prepend-icon="mdi-phone"
            maxlength=11
            required
          />

          <v-text-field
            label="住所*"
            v-model="provider_address"
            :rules="provider_address_rules"
            prepend-icon="mdi-map-marker"
            maxlength=255
            required
          />

          <v-text-field
            label="ホームページ"
            v-model="provider_url"
            :rules="provider_url_rules"
            prepend-icon="mdi-web"
            maxlength=255
          />

          <v-text-field
            label="パスワード*"
            v-model="provider_login_pwd" 
            v-bind:type="show_password ? 'text' : 'password'"
            v-bind:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="provider_login_pwd_rules"
            @click:append="show_password = !show_password" 
            prepend-icon="mdi-lock" 
            maxlength=20
            required
            clearable
          />

          <v-text-field
            label="パスワードの確認*"
            v-model="provider_login_pwd2" 
            v-bind:type="show_password ? 'text' : 'password'"
            :rules="provider_login_pwd_rules2"
            prepend-icon="mdi-check" 
            maxlength=20
            required
            clearable
          />

          <v-card-actions>
            <v-layout justify-end>
              <v-btn 
                class="info"
                @click="signup"
                :loading="show_loading"
                :disabled="!valid"
                >
                登録
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-form>

      </v-card-text>

      <v-card-text v-if="show_signuped_msg">
        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">登録完了</h1>
          <p>ご登録されたメールアドレスに確認メールを送信しました。</p>
        </div>
      </v-card-text>
    </v-card>

  </v-app>
</template>

<script>

export default {
  data () {
    const text_min = 4;
    const text_max = 20;
    const pwd_min = 8;
    const pwd_max = 20;
    return {
      valid: false,
      show_password : false,
      show_loading: false,
      show_signuped_msg: false,
      show_err_msg: false,
      err_messages: [],
      
      provider_name: '',
      provider_name_rules: [
        v => !!v || '会社名は必須項目です。',
        v => (!!v && v.length >= text_min && v.length <= text_max) || `会社名は${ text_min }～${ text_max }文字以上で入力してください。`
      ],
      provider_email: '',
      provider_email_rules: [
        v => !!v || 'メールアドレスは必須項目です。',
        v => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(v) || 'メールアドレスの入力が不正です。'
      ],
      provider_tel: '',
      provider_tel_rules: [
        v => !!v || '電話番号は必須項目です。',
        v => /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(v) || '電話番号の入力が不正です。'
      ],
      provider_address: '',
      provider_address_rules: [
        v => !!v || '住所は必須項目です。',
        v => (v.length >= text_min && v.length <= text_max) || `住所は${ text_min }～${ text_max }文字で入力してください。`
      ],
      provider_url: '',
      provider_url_rules: [
        v => !v || /^http(|s):\/\/.+/.test(v) || 'URLを入力してください。'
      ],
      provider_login_pwd: '',
      provider_login_pwd_rules: [
        v => !!v || 'パスワードを入力してください。',
        v => /^([a-zA-Z0-9!-/:-@¥[-`{-~]+)$/.test(v) || '使えない文字列が含まれています。(半角英数小文字大文字記号が使えます)',
        v => (v.length >= pwd_min && v.length <= pwd_max) || `パスワードは${ pwd_min }～${ pwd_max }文字で入力してください。`
      ],
      provider_login_pwd2: '',
      provider_login_pwd_rules2: [
        v => !!v || '確認のためパスワードを再入力してください。',
        v => v == this.provider_login_pwd || '入力されたパスワードが異なります。'
      ]
    };
  },

  methods: {
    validate () {
      return this.$refs.form.validate();
    },
    signup () {
      this.show_err_msg = false;
      this.err_messages = [];
      if(!this.validate()) return;

      this.show_loading = true;
      let login_id = this.generateId();
      // console.log("login_id:", login_id)
      this.$axios
        .post('providers/accounts',
          {
            provider_login_id: login_id,
            provider_login_pwd: this.provider_login_pwd,
            url: this.provider_url,
            tel: this.provider_tel,
            provider_name_ja: this.provider_name,
            email: this.provider_email,
            lat: "0.0",// PCからは住所登録があるのでとりあえずなし
            lon: "0.0",
            address: this.provider_address,
          }
        )
        .then((signup_response) => 
          {
            // this.$store.commit(
            //   'updateId', {
            //     provider_id: signup_response.data.provider_id,
            //     sess_id: signup_response.data.sess_id
            //   }
            // )

            this.$axios.post('mail/activate',
              { email: signup_response.data.email }
            )
            .then(() =>
              {
                this.show_signuped_msg = true;
              }
            )
            .catch((signup2_error) =>
              {
                console.log(signup2_error.response);
              }
            );
          }
        )
        .catch((signup_error) => 
          {
            console.log(signup_error.response);
            if(signup_error.response.data.err_messages!=null)
            {
              this.err_messages = signup_error.response.data.err_messages;
              this.show_err_msg = true;
              this.provider_email = "";
            }
          }
        )
        .finally(()=>
        {
          this.show_loading = false;
        });
    },
    getRandint (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    generateId () {
      return "CC" + ('00000000' + this.getRandint(0, 99999999)).slice(-8);
    }
  }
};
</script>

<style scoped>
.err_msg {
  color: red;
  font-size: 15px;
}
</style>