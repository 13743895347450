<template>
  <v-app>
    <v-container pt-2 pb-2 mt-2 mb-10>
      <v-layout justify-center>
        <v-avatar tile size="40%">
          <img src="@/assets/logo.png"> 
        </v-avatar>
      </v-layout>
    </v-container>

    <v-card width="600px" class="mx-auto mt-5" color="yellow lighten-5" elevation="18" shaped>
      <v-card-text v-if="show_email_msg">
        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">パスワード再発行</h1>
          <p>ご登録されたメールアドレスを入力してください。</p>
          <p class="err_msg" v-show="show_err_msg" v-for="msg in err_messages" v-bind:key="msg">{{ msg }}</p>
        </div>

        <v-form
          ref="form_email"
          v-model="valid_email"
          lazy-validation>
          <v-text-field
            label="メールアドレス*"
            v-model="provider_email"
            :rules="provider_email_rules"
            prepend-icon="mdi-email"
            maxlength=255
            required
          />
          <v-card-actions>
            <v-layout justify-end>
              <v-btn 
                class="info"
                @click="send_email"
                :loading="show_loading"
                :disabled="!valid_email"
                >
                送信
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card-text>

      <v-card-text v-if="show_changepwd_msg">
        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">パスワード再設定</h1>
          <p>新しいパスワードを設定してください。</p>
          <p class="err_msg" v-show="show_err_msg" v-for="msg in err_messages" v-bind:key="msg">{{ msg }}</p>
        </div>

        <v-form
          ref="form_pwd"
          v-model="valid_pwd"
          lazy-validation>
          <v-text-field
            label="パスワード*"
            v-model="provider_login_pwd" 
            v-bind:type="show_password ? 'text' : 'password'"
            v-bind:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="provider_login_pwd_rules"
            @click:append="show_password = !show_password" 
            prepend-icon="mdi-lock" 
            maxlength=20
            required
            clearable
          />

          <v-text-field
            label="パスワードの確認*"
            v-model="provider_login_pwd2" 
            v-bind:type="show_password ? 'text' : 'password'"
            :rules="provider_login_pwd_rules2"
            prepend-icon="mdi-check" 
            maxlength=20
            required
            clearable
          />

          <v-card-actions>
            <v-layout justify-end>
              <v-btn
                class="info"
                @click="send_pwd"
                :loading="show_loading"
                :disabled="!valid_pwd"
                >
                送信
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card-text>

      <v-card-text v-if="show_posted_msg">
        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">受付完了</h1>
          <p>ご登録されたメールアドレスにパスワード再設定のご案内を送信しました。</p>
        </div>
      </v-card-text>

      <v-card-text v-if="show_changedpwd_msg">
        <div class="layout column align-center">
          <h1 class="flex my-4 font-weight-bold">パスワード変更完了</h1>
          <router-link :to="{path: '/', query: { id: this.provider_login_id }}">ログインページへ</router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data () {
    const pwd_min = 8;
    const pwd_max = 20;
    return {
      valid_email: false,
      valid_pwd: false,
      show_password: false,
      show_loading: false,
      show_email_msg: this.$route.query.id == undefined,
      show_changepwd_msg: this.$route.query.id != undefined,
      show_changedpwd_msg: false,
      show_posted_msg: false,
      show_err_msg: false,
      err_messages: [],
      provider_login_id: this.$route.query.id != undefined ? this.$route.query.id : '',

      provider_email: '',
      provider_email_rules: [
        v => !!v || 'メールアドレスは必須項目です。',
        v => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(v) || 'メールアドレスの入力が不正です。'
      ],
      provider_login_pwd: '',
      provider_login_pwd_rules: [
        v => !!v || 'パスワードを入力してください。',
        v => /^([a-zA-Z0-9!-/:-@¥[-`{-~]+)$/.test(v) || '使えない文字列が含まれています。(半角英数小文字大文字記号が使えます)',
        v => (v.length >= pwd_min && v.length <= pwd_max) || `パスワードは${ pwd_min }～${ pwd_max }文字で入力してください。`
      ],
      provider_login_pwd2: '',
      provider_login_pwd_rules2: [
        v => !!v || '確認のためパスワードを再入力してください。',
        v => v == this.provider_login_pwd || '入力されたパスワードが異なります。'
      ]

    }
  },

  methods: {
    validate_email ()
    {
      return this.$refs.form_email.validate();
    },
    validate_pwd ()
    {
      return this.$refs.form_pwd.validate();
    },
    send_email ()
    {
      this.show_err_msg = false;
      this.err_messages = [];
      if(!this.validate_email()) return;

      this.show_loading = true;
      this.$axios.post('mail/reactivate',
      {
        email: this.provider_email
      })
      .then(() =>
      {
        this.show_email_msg = false;
        this.show_posted_msg = true;
      })
      .catch((reactivate_error)=>{
        console.log(reactivate_error.response);
        if(reactivate_error.response.data.err_messages != null)
        {
          this.err_messages = reactivate_error.response.data.err_messages;
          this.show_err_msg = true;
          this.provider_email = "";
        }
      })
      .finally(()=>
      {
        this.show_loading = false;
      });
    },
    send_pwd ()
    {
      this.show_err_msg = false;
      this.err_messages = [];
      if(!this.validate_pwd()) return;

      this.show_loading = true;
      this.$axios.post('provider/reactivate',
      {
        provider_login_id: this.provider_login_id,
        provider_login_pwd: this.provider_login_pwd
      })
      .then(() =>
      {
        this.show_email_msg = false;
        this.show_posted_msg = false;
        this.show_changepwd_msg = false;
        this.show_changedpwd_msg = true;
      })
      .catch((reactivate_error)=>{
        console.log(reactivate_error.response);
        if(reactivate_error.response.data.err_messages != null)
        {
          this.err_messages = reactivate_error.response.data.err_messages;
          this.show_err_msg = true;
          this.provider_login_pwd = "";
          this.provider_login_pwd2 = "";
        }
      })
      .finally(()=>
      {
        this.show_loading = false;
      });
    }
  }
};
</script>

<style scoped>
.err_msg {
  color: red;
  font-size: 15px;
}
</style>