<template>

  <div class="navigation">

    <v-navigation-drawer app v-model="setDrawer" clipped temporary>
      <v-container>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              メニュー
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item v-for="nav_list in nav_lists" :key="nav_list.name" :href="nav_list.to" >
            <v-list-item-icon>
              <v-icon>{{ nav_list.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
      </v-container>

      <template v-slot:append>
        <div class="logout">
          <v-btn 
          block 
          @click="logout()"
          color="primary"
          :loading="loading"
          >
            ログアウト
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

  </div>

</template>

<script>
export default {
  // サイドバーに格納するコンテンツ
  data () {
    return{
      loading: false,
      nav_lists: [
        {name: 'お問い合わせ',icon: 'mdi-comment-question', to: 'https://www.google.com/?hl=ja'},
        // {name: '設定',icon: 'mdi-cog'},
        // {name: '料金プランの確認',icon: 'mdi-cash-usd'},
      ]     
    }
  },
  // ヘッダーとの値受け渡し用のprops, computed
  props: {
    drawer: {
      type: Boolean,
      default: null,
    }
  },
  
  computed: {
    setDrawer: {
      get () { return this.drawer },
      set (val) { return this.$emit('update:drawer', val) }
    }
  },

  methods: {
    // ログアウトボタン押下時の処理
    logout: function() {
      this.loading = true;
      this.$axios
        .delete('providers/signout',
          { params: 
            {
              provider_id: this.$store.state.provider_id,
              sess_id: this.$store.state.sess_id,
            }
          }
        )
        .then(() => 
          {
            // console.log("logout_success");
            // console.log(logout_response);
            this.$store.commit(
              'deleteId', {
                provider_id: this.$store.state.provider_id,
                sess_id: this.$store.state.sess_id
              }
            )

            this.$router.push('/');
          }
        )
        .catch(() => 
          {
            console.log("logout_error");
            // console.log(logout_error);
          }
        )
        .finally(()=>{ this.loading = false; });
      
    }
  }
}
</script>

<style scoped>
/* .logout {
  margin-bottom: 35px;
} */
</style>