<template>

  <div class="call_history">

    <v-container text-center wrap md12>
      <v-row>
        <v-col>
          <v-card class="mx-auto" outlined max-width="1200">
            <v-card-title>
              <v-icon>mdi-history</v-icon>
                呼出履歴
            </v-card-title>
            <v-data-table 
              :headers="headers"
              :items="items"
              :items-per-page="30"
              :footer-props="{
                itemsPerPageOptions: [30]
              }"
              class="elevation-1"
            >
              <template v-slot:body="{items}">
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id">

                    <td v-if="item.bill_no == null">
                      受付番号 {{ item.accept_no }}
                    </td>

                    <td v-else>
                      札番号 {{ item.bill_no }}
                    </td>

                    <td>
                      {{ item.complate_dt }}
                    </td>

                    <td>
                      <v-btn
                        class="warning" small
                        :disabled="item.bill_no!=null"
                        @click="changeState(index)">
                        元に戻す
                      </v-btn>
                    </td>

                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
export default {
  name: 'CallHistory',
  data() {  
    return{
      items: [],
      headers: [
        {
          text: "受付番号",
          sortable: false,
          align: "center"
        },
        {
          text: "完了日時",
          sortable: false,
          align: "center"
        },
        {
          text: "元に戻す",
          sortable: false,
          align: "center"
        }
      ]
    } 
  },

  mounted () {
    // ページ読み込み時に受付カードを読み取る
    this.getAccepts();
  },

  methods: {
    getAccepts: function() {
      this.$axios
        .get('acceptcards', 
          { params: 
            {
              provider_id: this.$store.state.provider_id,
              sess_id: this.$store.state.sess_id,
              accept_mode: "h"
            }
          }
        )
      .then(call_hist_response => {
        this.acceptcards = call_hist_response;
        this.items = call_hist_response.data;
      });
    },
    updateUserStatus: function(item) {
      this.$axios
        .put('acceptcards/' + item.accept_key, 
          {
            provider_id: this.$store.state.provider_id,
            sess_id: this.$store.state.sess_id,
            accept_st: item.accept_st,
            call_plan_dt: "2021-04-27 12:00:00", // 未使用のため仮値
            place_seq: 1 // 未使用のため仮値
          }
        )
      .then(() => {
        this.getAccepts();
      });
    },
    changeState: function(index) {
      // 10->1への差し戻しのみなのでaccept_status=1固定。
      const accept_status = 1;
      this.items[index].accept_st = accept_status;
      this.updateUserStatus(this.items[index]);
    }
  }
}
</script>

<style scoped>
.call_history {
  text-align: center;
}
.v-subheader {
  text-align: center;
  font-size: 30px;
}
</style>