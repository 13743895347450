<template>

  <div class="WaitingList">
    <v-container text-center wrap md12>
      <v-card class="mx-auto" outlined max-width="1200">
        <v-card-title>
          <v-icon>mdi-account-clock</v-icon>
          現在の待ち人数：{{ count }}
        </v-card-title>
      </v-card>
    </v-container>
  </div>
  
</template>

<script>
export default {
  name: 'WaitingList',
  data () {
    return {
      count: 0
    }
  },

  mounted () {
    this.getEntryCount();
    this.setTimer(5 * 1000);
  },

  beforeDestroy () {
    this.clearTimer();
  },

  methods: {
    setTimer: function(msec) {
      clearInterval(this.timerObj);
      this.timerObj = setInterval(this.getEntryCount.bind(this), msec);
    },
    clearTimer: function() { clearInterval(this.timerObj); },
    getEntryCount: function(){
      this.$axios
      .get('provider/entorycount',
      { params:
      {
        provider_id: this.$store.state.provider_id,
        sess_id: this.$store.state.sess_id
      }})
      .then((response) => {
        this.count = response.data['count'];
      })
    }
  }
}
</script>

<style scoped>
</style>