<template>

  <div class="news_list">

    <v-container text-center wrap md12>
      <v-card class="mx-auto" outlined max-width="1200">
        <v-data-table
          :headers="headers"
          :items="news"
          :expanded.sync="expanded"
          show-expand
          :items-per-page="30"
          :footer-props="{
            itemsPerPageOptions: [30]
          }"
          class="elevation-1"
          item-key="news_body"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>C-llaCellからのお知らせ</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">{{ item.news_body }}</td>
          </template>

        </v-data-table>
      </v-card>
    </v-container>
  </div>

</template>

<script>
export default {
  // お知らせ一覧格納用の配列初期化
  data () {
    return {
      expanded: [],
      news: [],
      headers: [
        {
          text: "お知らせタイトル",
          sortable: false,
          width: "70%",
          value: 'news_title'
        },
        {
          text: "掲載日時",
          sortable: false,
          value: 'posting_from',
          align: "center"
        }
      ],
      // dialog: false
    } 
  },
  
  // お知らせ一覧取得
  mounted () {
    this.$axios
      .get('providers/information')
      .then(news_list_response => {
        // responseデータを格納
        this.news = news_list_response.data

        // console.log(this.news)
      })
  },

}
</script>

<style scoped>
.news_list {
  text-align: center;
}

.v-subheader {
  text-align: center;
  font-size: 30px;
}
</style>