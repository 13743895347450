<template>

  <div id="qr_display">
    <v-container text-center mt-12 mb-8>
      <v-layout>
        <v-flex>
          <qriously :value="this.$store.state.provider_id" :size="200" />
        </v-flex>
      </v-layout>
    </v-container>
    
    <div class="number_input">
      <v-row justify="center">

        <v-card outlined  elevation="12" justify="center" width="600">
          <v-card-title>番号札による呼出はこちらから入力</v-card-title>
          <span class="accept_success" v-show="show">
            受付しました
          </span>
          
          <v-card-text>
            <v-form ref="accept_form" @submit.prevent>
              <v-text-field
                label="8桁以内の数値を入力してください"
                counter="8"
                type="number"
                hint="入力中。最大8文字"
                color="green darken-5"
                clearable
                maxlength="8"
                prepend-inner-icon="mdi-counter"
                v-model="bill_no"
                :rules="rules.addEntry_rule"
              >
              </v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-layout justify-end>
              <v-btn class="info"
              @click="addEntry()"
              :loading="loading"
              >
              受付する
              </v-btn>
            </v-layout>
          </v-card-actions>

        </v-card>

      </v-row>
    </div>
    
  </div>
</template>

<script>
// bill_noが存在するときはcall_listの呼出ボタンを消す設定にする

export default {  
  // 受付番号を、手動発行した際の入力値を初期化
  data: () => ({
    bill_no: "",
    accept_st: "",
    show: false,
    clearable: false,
    success: false,
    rules: {
      addEntry_rule: [
        v => !!v || "",
        v => v > 0 || "正の整数で入力してください。",
        v => v < 100000000 || "8桁以内で入力してください"
      ]
    },
    loading: false
  }),

  methods: {
    addEntry: function() {
      if (this.$refs.accept_form.validate()) {
      // すべてのバリデーションが通過したときのみ実行
        this.success = true;
        this.loading = true;
        this.$axios
          .post('acceptcards',
            {
              provider_id: this.$store.state.provider_id,
              sess_id: this.$store.state.sess_id,
              place_name: '',
              user_no: '',
              accept_no: '',
              bill_no: this.bill_no,
              accept_dt: '',
              call_plan_dt: '',
              complate_dt: '',
              accept_st: 2
            }
          )
          .then(() => 
            {
              this.accept_display();
              // console.log(add_entry_response)
              this.bill_no = "";
              this.accept_state = true;
              this.$refs.accept_form.reset();
            }
          )
          .finally(()=>{ this.loading = false; });
          // .catch((add_entry_error) =>
          //   {
          //     console.log(add_entry_error);
          //   }
          // );
      } else {
        this.success = false;
        this.bill_no = "";
      }
    },

    // 受付完了を3秒間表示後、自動的に消す
    accept_display: function() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        }, 3000
      );
    }
  }
}
</script>

<style scoped>
.number_input {
  text-align: center;
}

.accept_success{
  color: orange;
}
</style>