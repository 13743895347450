import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
// import axios from 'axios';


Vue.use(Vuex)

// ログイン用処理
const store = new Vuex.Store({
    state: {
      // login_token: {
        provider_id: null,
        sess_id: null,
      // },

      // toast: {
      //   meg: 'null',
      //   color: 'error',
      //   timeout: 10000
      // },
    },

    getters: {
      isAuthenticated: state => state.sess_id !==null
    },

    mutations: {
      // call-appサーバーが返却するユーザー情報
      updateId (state, {provider_id, sess_id}) {
        state.provider_id = provider_id;
        state.sess_id = sess_id;
      },

      // logoutボタン押下時にprovider_idとsession_idを破棄
      deleteId (state) {
        state.provider_id = null;
        state.sess_id = null;
      },

      // setToast (state, payload) {
      //   state.toast = payload
      // }
    },

    actions: {
      // getToast ({ commit }, toast) {
      //   toast.color = toast.color || 'error'
      //   toast.timeout = toast.timeout || 10000
      //   commit('setToast', toast)
      // }
    },
    
    plugins: [createPersistedState({
      // ストレージのキー指定  
      key: 'vuex',
      paths: [
        'provider_id', 
        'sess_id'
      ],
      // ストレージの種類指定
      storage: window.sessionStorage
    })],


})

export default store