<template>

  <div>
    <Navigation :drawer.sync="drawer"></Navigation>
    
    <v-app-bar color="#FFA000" dark app clipped-left>
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="main_title">C-llaCell board</v-toolbar-title>

      <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn outlined text to="/qrdisplay">
            <v-icon>mdi-qrcode</v-icon>
              <span class="header_text">
                QRコード
              </span>
          </v-btn>
          <v-btn outlined text to="/calllist">
            <v-icon>mdi-bell</v-icon>
              <span class="header_text">
                呼出リスト
              </span>
          </v-btn>
          <v-btn outlined text to="/callhistory">
            <v-icon>mdi-history</v-icon>
              <span class="header_text">
                呼出履歴
              </span>
          </v-btn>
          <v-btn outlined text to="/newslist">
            <v-icon>mdi-newspaper-variant</v-icon>
              <span class="header_text">
                お知らせ
              </span>
          </v-btn>
          <v-btn outlined text to="/settings">
            <v-icon>mdi-cog</v-icon>
              <span class="header_text">
                設定
              </span>
          </v-btn>
        </v-toolbar-items>

    </v-app-bar>

  </div>

</template>

<script>
import Navigation from './Navigation.vue'

export default {
  // サイドバーの表示、非表示切り替え用data
  data () {
    return {
      drawer: false,   
    }
  },

  components: {
    Navigation,
  }
  
}
</script>

<style scoped>
.main_title {
  font-size: 24px;
}

.v-icon {
  margin-right: 3px;
}

.header_text {
  font-size: 100%;
}
</style>