import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/Login.vue'
import ProviderSignup from './views/ProviderSignup.vue'
import Reactivate from './views/Reactivate.vue'
import QrDisplay from './views/QrDisplay.vue'
import CallList from './views/CallList.vue'
import CallHistory from './views/CallHistory.vue'
import NewsList from './views/NewsList.vue'
import Settings from './views/Settings.vue'
import WaitingList from './views/WaitingList.vue'
import store from '@/store'


Vue.use(Router)

var routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/signup',
    name: 'signup',
    component: ProviderSignup,
    meta: { isPublic: true }
  },
  {
    path: '/reactivate',
    name: 'reactivate',
    component: Reactivate,
    meta: { isPublic: true }
  },
  {
    path: '/newslist',
    name: 'newslist',
    component: NewsList,
  },
  {
    path: '/calllist',
    name: 'calllist',
    component: CallList
  },
  {
    path: '/callhistory',
    name: 'callhistory',
    component: CallHistory
  },
  {
    path: '/qrdisplay',
    name: 'qrdisplay',
    component: QrDisplay
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/waitinglist',
    name: 'waitinglist',
    component: WaitingList
  }
];

var router = new Router({
  mode: 'history',                  
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.isPublic)){
    // public page
    if (store.getters.isAuthenticated && to.matched.some(record => record.name == 'login')) {
      next({ name: 'qrdisplay' });
    } else {
      next();
    }

  } else if (store.getters.isAuthenticated) {
    // private page.
    next();

  } else {
    // other.
    next({ name: 'login', query: { redirect: to.fullPath }});

  }
});

export default router;
