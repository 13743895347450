import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import Vuex from 'vuex';
import store from '@/store'
import vuetify from './plugins/vuetify';
import VueQriously from 'vue-qriously';

import CllacellServerApi from './plugins/cllacell-server-api';

Vue.config.productionTip = false
// インスタンスプロパティに追加
Vue.prototype.$axios = axios

// c-llacell 本番環境 api用パス
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// Vue.use(router)
Vue.use(Vuex)
Vue.use(VueQriously)

Vue.use(CllacellServerApi)

new Vue({
  router,
  // Vuex,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
